import React, { useState, useEffect } from 'react'
import { Formik,Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import _ from 'lodash'

import TextInput from '../../common/TextInput/TextInputV2'
import MainPanel from '../../common/MainPanel/MainPanel'
import InfoPanel from '../../common/InfoPanel/InfoPanel'
import FileButton from '../../common/FileButton/FileButton'
import FormRadioInput from '../../common/FormRadioInput/FormRadioInput'
import styles from '../SignupPage/styles/Signup.module.scss'
import PhoneInput from '../../common/PhoneInput/PhoneInput'
import { normalizeInput } from '../../../utils'
import CreatableSelect from 'react-select/creatable'
import {
    H1,
    Label,
    Message,
    P,
    StyledForm,
    Button,
    ErrorMessage,
} from './styles/CreateProfilePage.styled'
import { Alert, Spinner, Row, Col } from 'react-bootstrap'
import { set } from 'react-ga'
import botImage from '../../../../assets/images/bot_img.png'

const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION'
const CHOOSE_ROLE = 'CHOOSE_ROLE'

const colourStyles = {
    control: (styles) => ({
        ...styles,
        marginTop: '15px',
        backgroundColor: '#f5f7ff',
        zIndex: '15',
        backgroundClip: 'padding-box',
        border:'1px solid #ced4da',
        borderRadius: '0.25rem',
        width:'100%',
        height:'calc(1.5em + 0.75rem + 2px)'
    }),
}

const ContactDetailInterviewPage = ({
    formData,
    setFormData,
    submitData,
    loading,
    setResult,
    result,
    selectedRole,
    user,
    currentUser,
    showInfoPanel
}) => {
    const [roleDescription, setRoleDescription] = useState('')
    const [resume, setResume] = useState(formData.contactDetails.resume)
    const [resumeError, setResumeError] = useState(null)
    const [states, setStates] = useState([])
    const [phoneNumber, setPhoneNumber] = useState(
        formData.contactDetails.phoneNumber
    )
    const [selectedLocation, setSelectedLocation] = useState('')
    const [locationOptions, setLocationOptions] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [appendLinkedinValue, setAppendLinkedinValue] = useState(formData.contactDetails.linkedinProfile)
    const [phoneError, setPhoneError] = useState()
    const [locationError, setLocationError] = useState()
    const [source, setSource] = React.useState();
    const [fileError, setFileError] = useState('')
    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                resume: resume,
            },
        }))
    }, [resume])

    useEffect(() => {
        fetchRequired()
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        // const url = URL.createObjectURL(file);
         if(file?.size > 50000000){
                setFileError( 'The uploaded file should be less than 50MB')
                setSource(file);
			}
            else{
                if((!file.type.includes('mp4')) && !(file.type.includes('mp3')) && !(file.type.includes('audio')) && !(file.type.includes('video'))){
                    setFileError("invalid format")
                    setSource(file);
                }else{              
                    setFileError('')    
                    setSource(file);
                }
            }
      };

    useEffect(() => {
        setResult({})
        if (isSubmitted) {
            if (selectedRole === 'talent') {
                if (!resume) {
                    setResumeError('Resume is required')
                    return
                }
                submitData()
            } else if (selectedRole === 'admin') {
                submitData()
            } else if (selectedRole === 'recruiter') {
                submitData()
            } else {
                if (user.organization_id) {
                    submitData()
                } else {
                    setFormData((prev) => ({
                        ...prev,
                        step: 'CREATE_ORGANIZATION',
                    }))
                }
            }
        }
        setIsSubmitted(false)
    }, [isSubmitted])

    const fetchRequired = async () => {
        const url = `/signup/contracts?name=contact_note&role=${selectedRole}`
        axios.get(url).then((res) => {
            setRoleDescription(res.data?.content)
        })

        const lookupsUrl = '/signup/lookups'
        axios.get(lookupsUrl).then((res) => {
            setStates([...res.data.states])
        })

        const url1 = `/filter_candidate_on_location`
        const formData = new FormData()
        formData.append('filter_word', '')
        const response = await axios
            .post(url1, formData)
            .then((res) => res.data)
            .catch((error) => console.log(error))
        const locationArray = response.filter.map((res) => ({
            value: `${res.city}, ${res.state}`,
            label: `${res.city}, ${res.state}`,
        }))
        setLocationOptions([...locationArray])
    }

    const employerFields = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
        title: formData.contactDetails.title,
        phoneNumber: phoneNumber,
        streetAddress: formData.contactDetails.streetAddress,
        state: formData.contactDetails.state,
        zipCode: formData.contactDetails.zipCode,
    }

    const recruiterFileds = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
        location: selectedLocation,
        phoneNumber: phoneNumber,
        resume: formData.contactDetails.resume,
        linkedinProfile: formData.contactDetails.linkedinProfile,
        ein_number: formData.contactDetails.ein_number,
        calendly_link: formData.contactDetails.calendly_link,
    }

    const adminFileds = {
        firstName: formData.contactDetails.firstName,
        lastName: formData.contactDetails.lastName,
    }

    const candidateFields = {
        firstName: (formData.contactDetails.firstName != '') ? formData.contactDetails.firstName : (user?.first_name) ? user?.first_name : '',
        lastName: (formData.contactDetails.lastName != '') ? formData.contactDetails.lastName : (user?.last_name) ? user?.last_name : '',
        linkedinProfile: formData.contactDetails.linkedinProfile,
        phoneNumber: phoneNumber,
        resume: formData.contactDetails.resume,
        activeJobSeeker: '',
    }

    const employerValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),

        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        title: Yup.string().required('Title is required').trim(),
        streetAddress: Yup.string().required('Company Address is required').trim(),
        state: Yup.string().required('State is required'),
        zipCode: Yup.string()
            .required('Zip Code is required')
            .test(
                'zip code digits only',
                'Zip code must contain 5 digits only',
                function (value) {
                    const regex = /^\d{5}$/g
                    return regex.test(value)
                }
            ),
    })

    const recruiterValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Must be exactly 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        ein_number: Yup.string()
            .required('EIN number is required').max(10, 'No more than 10 characters'),
        calendly_link: Yup.string()
            .required('Calendly Link is required'),
        linkedinProfile: Yup.string()
            .notRequired()
            .nullable(true)
            .test(
                'linkedin only',
                'Invalid url, please add Linkedin url only',
                function (value) {
                    if(value){
                        try {
                            let hostname = new URL(value).hostname
                            setAppendLinkedinValue(value)
                            if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                                return false
                            }
                            return (
                                hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                            )
                        } catch (error) {
                            setAppendLinkedinValue(value)
                            if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                                return false
                            }
                            let checkDomainExist = value.indexOf("linkedin.com/")
                            if(checkDomainExist == -1){
                                return false
                            }else if(checkDomainExist == 0){
                                let splitValue = value.split('linkedin.com/')
                                if(splitValue[1] === ''){ 
                                    return false
                                }
                                let tempURL = 'https://www.'+ value
                                setAppendLinkedinValue(tempURL)
                                return true
                            }else{
                                let splitValue = value.split('linkedin.com/')
                                if(splitValue[0] === 'www.'){
                                    let tempURL = 'https://'+ value
                                    setAppendLinkedinValue(tempURL)
                                    return true
                                }
                            }
                            return false
                        }
                    }else{
                        return true
                    }
                }
            ),
    })

    const candidateValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        linkedinProfile: Yup.string()
            .notRequired()
            .nullable(true)
            .test(
                'linkedin only',
                'Invalid url, please add Linkedin url only',
                function (value) {
                    if(value){
                        try {
                            let hostname = new URL(value).hostname
                            setAppendLinkedinValue(value)
                            if(value === 'https://www.linkedin.com' || value === 'https://www.linkedin.com/'){
                                return false
                            }
                            return (
                                hostname === 'linkedin.com' || hostname === 'www.linkedin.com'
                            )
                        } catch (error) {
                            setAppendLinkedinValue(value)
                            if(value === 'www.linkedin.com/' || value === 'https://www.linkedin.com/' || value === 'linkedin.com/' || value === 'linkedin.com' || value === 'https://linkedin.com' || value === 'https://linkedin.com/'){
                                return false
                            }
                            let checkDomainExist = value.indexOf("linkedin.com/")
                            if(checkDomainExist == -1){
                                return false
                            }else if(checkDomainExist == 0){
                                let splitValue = value.split('linkedin.com/')
                                if(splitValue[1] === ''){ 
                                    return false
                                }
                                let tempURL = 'https://www.'+ value
                                setAppendLinkedinValue(tempURL)
                                return true
                            }else{
                                let splitValue = value.split('linkedin.com/')
                                if(splitValue[0] === 'www.'){
                                    let tempURL = 'https://'+ value
                                    setAppendLinkedinValue(tempURL)
                                    return true
                                }
                            }
                            return false
                        }
                    }else{
                        return true
                    }
                }
            ),
        activeJobSeeker: Yup.string().required(
            'Please select only one checkbox'
        ),
    })

    const adminValidation = Yup.object({
        firstName: Yup.string()
            .required('First Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'first name alphabets only',
                'First Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
        lastName: Yup.string()
            .required('Last Name is required')
            .max(50, 'Should not be exceed 50 characters')
            .test(
                'last name alphabets only',
                'Last Name can only contain alphabet characters and one space in between if there are two words',
                function (value) {
                    const regex = /^[a-zA-Z.]+(\s[a-zA-Z.]+)?$/g
                    return regex.test(value)
                }
            ),
    })

    const handleChange = ({ target: { value } }) => {
        setPhoneError('')
        setPhoneNumber(normalizeInput(value, ''))
    }
    const locationChange = (e) => {
        setSelectedLocation(e.value)
        setLocationError('')
    }

    const handleSubmit = async (values) => {
        if (formData.selectedRole === 'talent' && !resume) {
            setResumeError('Resume is required')
            return
        }
        if (selectedRole === 'recruiter' && !phoneNumber) {
            return
        }
        if (
            phoneNumber.length != 0 &&
            phoneNumber.length != 14
            ) {
            return
        }
        if (formData.selectedRole === 'talent' && phoneNumber.length != 0 && phoneNumber.length != 14) {
            return
        }
        if (selectedRole === 'recruiter' &&
        _.isEmpty(selectedLocation)) {
            return
        }

        if(values.linkedinProfile){
            values.linkedinProfile = appendLinkedinValue
        }
        setFormData((prev) => ({
            ...prev,
            contactDetails: {
                ...prev.contactDetails,
                ...values,
                resume: resume,
                phoneNumber: phoneNumber,
                location: selectedLocation,
            },
        }))
        setIsSubmitted(true)
    }
    
    return (
        <>
        <MainPanel>
            <section className={`container d-flex justify-content-center align-items-center ${styles.sectionMargin}`}>
                <Row className="d-flex w-75 shadow-lg">
                    <Col className="col-md-6 justify-content-center d-flex flex-column text-left" style={{ background: "linear-gradient(270deg, #02315F 0%, #022D57 98.46%)",borderRadius: "20px 0px 0px 20px" }}>
                        <div className={styles.mainwelcomeSpace} style={{margin: 'auto'}}>
                            <h1 className='text-white mt-5'>Welcome to CardinalTalent.AI!</h1>
                            <p className='text-white'>
                            You're just a few easy steps away from completing your profile and
                            launching your AI interview.
                            </p>
                        </div>
                        <div className="cardinal-image mt-5 row">
                            <img src={botImage} className='w-100' alt="Cardinal AI Bot" style={{borderRadius: "20px 0px 0px 20px",maxHeight: "300px"}} />
                        </div>
                    </Col>
                    <Col className="col-md-6 px-5 py-4">
                        <h5 class="font-weight-bold mb-3 text-left">Personal Information</h5>
                        <Formik
                        initialValues={
                            selectedRole === 'employer'
                                ? employerFields
                            :selectedRole === 'recruiter'
                                ? recruiterFileds
                            :selectedRole === 'admin'
                                ? adminFileds
                            : candidateFields
                        }
                        validationSchema={
                            selectedRole === 'employer'
                                ? employerValidation
                            :selectedRole === 'recruiter'
                                ? recruiterValidation
                            :selectedRole === 'admin'
                                ? adminValidation
                            : candidateValidation
                        }
                        validate={(values) => {
                            const errors = {}
                            if (selectedRole === 'talent' && !resume) {
                                errors.resume = 'Resume is required'
                                setResumeError(errors.resume)
                            }
                            if (selectedRole === 'talent' && !phoneNumber) {
                                errors.phoneNumber = 'Phone Number required'
                                setPhoneError('Phone Number required')
                            }
                            if (
                                phoneNumber.length != 0 &&
                                phoneNumber.length != 14
                                ) {
                                errors.phoneNumber = 'Accepts only 10 digits'
                            }
                            if (
                                selectedRole === 'recruiter' &&
                                _.isEmpty(selectedLocation)
                                ) {
                                    setLocationError('Location is required')
                                }
                                if (selectedRole === 'recruiter' && !phoneNumber) {
                                    errors.phoneNumber = 'Phone Number required'
                                    setPhoneError('Phone Number required')
                                }
                                if (
                                    phoneNumber.length != 0 &&
                                    phoneNumber.length != 14
                                    ) {
                                    errors.phoneNumber = 'Accepts only 10 digits'
                                }
                                const  validfun = (value) => {
                                    if( value != '' && value != undefined){
                                        const regex = /^[0-9]{10,15}$/
                                        console.log(regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim()))
                                        return regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim())
                                    }
                                    return true
                                }
                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setFileError('')
                            setFormData({
                                ...formData,
                                contactDetails: {
                                    ...values,
                                    phoneNumber: phoneNumber,
                                    resume: resume,
                                    audio_video_file: source
                                },
                            })
                            handleSubmit(values)
                        }}
                    >
                        {(formik) => {
                            const { errors } = formik
                            return (
                                <Form>
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="First Name*"
                                        name="firstName"
                                        type="text"
                                        id="firstName"
                                        placeholder="First Name*"
                                        width="20rem"
                                        maxLength={50}
                                    />
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="Last Name*"
                                        name="lastName"
                                        type="text"
                                        id="lastName"
                                        placeholder="Last Name*"
                                        width="20rem"
                                        maxLength={50}
                                    />
                                    {selectedRole === 'employer' ? (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Title*"
                                                name="title"
                                                type="text"
                                                id="title"
                                                placeholder="Title*"
                                                width="20rem"
                                                maxLength={200}
                                            />
                                            <TextInput
                                                label="Phone #"
                                                name="phoneNumber"
                                                type="text"
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                            />
                                        </>
                                    ) : selectedRole === 'recruiter' ? (
                                        <>
                                            <div
                                                // style={{
                                                //     width: '345px',
                                                //     float: 'left',
                                                // }}
                                            >
                                                <CreatableSelect
                                                    name="streetAddress"
                                                    id="streetAddress"
                                                    placeholder="Location*"
                                                    // isClearable
                                                    onChange={(e) =>
                                                        locationChange(e)
                                                    }
                                                    options={locationOptions}
                                                    styles={colourStyles}
                                                />
                                                <ErrorMessage>
                                                    {locationError}
                                                </ErrorMessage>
                                            </div>
                                            <TextInput
                                                label="Phone*"
                                                name="phoneNumber"
                                                type="text"
                                                isRequired={true}
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                            />
                                             <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Linkedin Profile"
                                                    name="linkedinProfile"
                                                    type="text"
                                                    placeholder="Linkedin Profile"
                                                    id="linkedinProfile"
                                                    width="20rem"
                                                  
                                                />
                                            <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="EIN Number*"
                                                    name="ein_number"
                                                    type="text"
                                                    id="ein_number"
                                                    placeholder="EIN Number*"
                                                    width="20rem"
                                                  
                                                />
                                            <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Calendly Link*"
                                                    name="calendly_link"
                                                    placeholder="Calendly Link*"
                                                    type="text"
                                                    id="calendly_link"
                                                    width="20rem"
                                                />
                                        </>
                                    ) : null}

                                    {selectedRole === 'employer' && (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Company Address*"
                                                name="streetAddress"
                                                type="text"
                                                placeholder="Company Address*"
                                                id="streetAddress"
                                                width="20rem"
                                            />
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                as="select"
                                                name="state"
                                                type="text"
                                                id="state"
                                                width="8rem"
                                            >
                                                <option value="">State*</option>
                                                {states.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>

                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Zip Code*"
                                                name="zipCode"
                                                placeholder="Zip Code*"
                                                type="text"
                                                id="zipCode"
                                                width="10rem"
                                            />
                                        </>
                                    )}

                                    

                                    {selectedRole === 'talent' && (
                                        <>
                                            <div className={styles.uplodDiv}>
                                                <div
                                                    className={styles.uplodDivs}
                                                >
                                                    <label style={{color:'#3a5182',fontSize: '14px',display: 'inline-flex'}}>Resume*</label>
                                                    <FileButton
                                                        label="Upload Resume"
                                                        type="button"
                                                        width={321}
                                                        file={resume}
                                                        resumeError={
                                                            resumeError
                                                        }
                                                        setResumeError={
                                                            setResumeError
                                                        }
                                                        getFile={(file) =>
                                                            setResume(file)
                                                        }
                                                    />
                                                </div>
                                                <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Linkedin Profile"
                                                    name="linkedinProfile"
                                                    type="text"
                                                    id="linkedinProfile"
                                                    width="20rem"
                                                />
                                            </div>
                                            <div className={styles.phoneDiv}>
                                                <div>
                                                <TextInput
                                                    name="phoneNumber"
                                                    type="text"
                                                    id="phoneNumber"
                                                    placeholder="(xxx) xxx-xxxx"
                                                    width="20rem"
                                                    onChange={handleChange}
                                                    value={phoneNumber}
                                                    />
                                                    <p style={{color:'red' ,fontSize:'10px'}}>{phoneError}</p>
                                                    </div>
                                                <div
                                                    className={
                                                        styles.formDetailscheckbox
                                                    }
                                                >
                                                    <p>
                                                        Are you an active job
                                                        seeker?
                                                    </p>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="Yes, I am actively searching for a
                                                        job"
                                                        >
                                                        Yes, I am actively
                                                        searching for a job
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, but I am open to the right
                                                        opportunity"
                                                        >
                                                        No, but I am open to the
                                                        right opportunity
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, I am just browsing"
                                                        >
                                                        No, I am just browsing
                                                    </FormRadioInput>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {selectedRole === 'talent' && 
                                        <>
                                        <div style={{display:"flex", flexDirection:'column', width:"24%"}}>
                                            <label style={{fontSize:'11px', textAlign:'center'}}>Video or Audio Intro</label>
                                      
                                            <label className={styles.uploadFileLabel}  for="actual-btn" >Upload file</label>
                                            <input
                                                id="actual-btn"
                                                hidden
                                                //  ref={inputRef}
                                                className="VideoInput_input"
                                                type="file"
                                                onChange={handleFileChange}
                                                accept=".mov,.mp4, .mp3"
                                            />
                                        </div>
                                            </>
                                    }
                                    {source && (
                                        <><span className={styles.nofilechosen}>{ source && source?.name || 'No file chosen'}</span>
                                    
                                        <span
                                        className={styles.closeFileIcon}
                                        onClick={() => {
                                            setSource(null)
                                            setFileError('')
                                        }}
                                        >
                                            x
                                        </span>
                                        <p style={{color:'red', fontSize:'13px'}}>{fileError}</p>
                                        </>
                                    )}
                                    
                                    <Button
                                    className='mt-4 float-right'
                                        type="submit"
                                        disabled={loading ||fileError }
                                        >
                                        {loading ? (
                                            <Spinner
                                                animation="border"
                                                variant="light"
                                            />
                                        ) : selectedRole === 'employer' &&
                                            !user.organization_id ? (
                                            'Next'
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                               
                                    {result && (
                                        <Row jContent="center" mt="18px">
                                            <Message color={result.color}>
                                                {result.message}
                                            </Message>
                                        </Row>
                                    )}
                                </Form>
                            )
                        }}
                        </Formik>
                    </Col>
                </Row>
            </section>
        </MainPanel>
            {/* <div className={`${styles.signUpForm}`}>
                <MainPanel>
                    

                    <Formik
                        initialValues={
                            selectedRole === 'employer'
                                ? employerFields
                            :selectedRole === 'recruiter'
                                ? recruiterFileds
                            :selectedRole === 'admin'
                                ? adminFileds
                            : candidateFields
                        }
                        validationSchema={
                            selectedRole === 'employer'
                                ? employerValidation
                            :selectedRole === 'recruiter'
                                ? recruiterValidation
                            :selectedRole === 'admin'
                                ? adminValidation
                            : candidateValidation
                        }
                        validate={(values) => {
                            const errors = {}
                            if (selectedRole === 'talent' && !resume) {
                                errors.resume = 'Resume is required'
                                setResumeError(errors.resume)
                            }
                            if (selectedRole === 'talent' && !phoneNumber) {
                                errors.phoneNumber = 'Phone Number required'
                                setPhoneError('Phone Number required')
                            }
                            if (
                                phoneNumber.length != 0 &&
                                phoneNumber.length != 14
                                ) {
                                errors.phoneNumber = 'Accepts only 10 digits'
                            }
                            if (
                                selectedRole === 'recruiter' &&
                                _.isEmpty(selectedLocation)
                                ) {
                                    setLocationError('Location is required')
                                }
                                if (selectedRole === 'recruiter' && !phoneNumber) {
                                    errors.phoneNumber = 'Phone Number required'
                                    setPhoneError('Phone Number required')
                                }
                                if (
                                    phoneNumber.length != 0 &&
                                    phoneNumber.length != 14
                                    ) {
                                    errors.phoneNumber = 'Accepts only 10 digits'
                                }
                                const  validfun = (value) => {
                                    if( value != '' && value != undefined){
                                        const regex = /^[0-9]{10,15}$/
                                        console.log(regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim()))
                                        return regex.test(value.replace('(','').replace(')','').replace('-','').replace(' ','').trim())
                                    }
                                    return true
                                }
                            return errors
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setFileError('')
                            setFormData({
                                ...formData,
                                contactDetails: {
                                    ...values,
                                    phoneNumber: phoneNumber,
                                    resume: resume,
                                    audio_video_file: source
                                },
                            })
                            handleSubmit(values)
                        }}
                    >
                        {(formik) => {
                            const { errors } = formik
                            return (
                                <StyledForm className={styles.mainoragiForm}>
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="First Name*"
                                        name="firstName"
                                        type="text"
                                        id="firstName"
                                        width="20rem"
                                        maxLength={50}
                                    />
                                    <TextInput
                                        className={styles.contactDetailInpu}
                                        label="Last Name*"
                                        name="lastName"
                                        type="text"
                                        id="lastName"
                                        width="20rem"
                                        maxLength={50}
                                        style={{
                                            marginLeft: '10px',
                                        }}
                                    />
                                    {selectedRole === 'employer' ? (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Title*"
                                                name="title"
                                                type="text"
                                                id="title"
                                                width="20rem"
                                                maxLength={200}
                                            />
                                            <PhoneInput
                                                label="Phone #"
                                                name="phoneNumber"
                                                type="text"
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                                style={{ marginLeft: '10px' }}
                                            />
                                        </>
                                    ) : selectedRole === 'recruiter' ? (
                                        <>
                                            <div
                                                style={{
                                                    width: '345px',
                                                    float: 'left',
                                                }}
                                            >
                                                <Label>{'Location*'}</Label>
                                                <CreatableSelect
                                                    name="streetAddress"
                                                    id="streetAddress"
                                                    placeholder="Location"
                                                    // isClearable
                                                    onChange={(e) =>
                                                        locationChange(e)
                                                    }
                                                    options={locationOptions}
                                                    styles={colourStyles}
                                                />
                                                <ErrorMessage>
                                                    {locationError}
                                                </ErrorMessage>
                                            </div>
                                            <PhoneInput
                                                label="Phone*"
                                                name="phoneNumber"
                                                type="text"
                                                isRequired={true}
                                                id="phoneNumber"
                                                placeholder="(xxx) xxx-xxxx"
                                                width="20rem"
                                                onChange={handleChange}
                                                value={phoneNumber}
                                                style={{ marginLeft: '10px' }}
                                            />
                                             <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Linkedin Profile"
                                                    name="linkedinProfile"
                                                    type="text"
                                                    id="linkedinProfile"
                                                    width="20rem"
                                                  
                                                />
                                            <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="EIN Number*"
                                                    name="ein_number"
                                                    type="text"
                                                    id="ein_number"
                                                    width="20rem"
                                                    style={{ marginLeft: '10px' }}
                                                  
                                                />
                                            <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Calendly Link*"
                                                    name="calendly_link"
                                                    type="text"
                                                    id="calendly_link"
                                                    width="20rem"
                                                />
                                        </>
                                    ) : null}

                                    {selectedRole === 'employer' && (
                                        <>
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Company Address*"
                                                name="streetAddress"
                                                type="text"
                                                id="streetAddress"
                                                width="20rem"
                                            />
                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                as="select"
                                                label="State*"
                                                name="state"
                                                type="text"
                                                id="state"
                                                width="8rem"
                                                style={{
                                                    marginLeft: '10px',
                                                }}
                                            >
                                                <option value=""></option>
                                                {states.map(
                                                    ({ key, value }) => {
                                                        return (
                                                            <option
                                                                key={key}
                                                                value={key}
                                                            >
                                                                {value}
                                                            </option>
                                                        )
                                                    }
                                                )}
                                            </TextInput>

                                            <TextInput
                                                className={
                                                    styles.contactDetailInpu
                                                }
                                                label="Zip Code*"
                                                name="zipCode"
                                                type="text"
                                                id="zipCode"
                                                width="10rem"
                                                style={{
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        </>
                                    )}

                                    

                                    {selectedRole === 'talent' && (
                                        <>
                                            <div className={styles.uplodDiv}>
                                                <div
                                                    className={styles.uplodDivs}
                                                >
                                                    <label style={{color:'#3a5182',fontSize: '14px',display: 'inline-flex'}}>Resume*</label>
                                                    <FileButton
                                                        label="Upload Resume"
                                                        type="button"
                                                        width={321}
                                                        file={resume}
                                                        resumeError={
                                                            resumeError
                                                        }
                                                        setResumeError={
                                                            setResumeError
                                                        }
                                                        getFile={(file) =>
                                                            setResume(file)
                                                        }
                                                    />
                                                </div>
                                                <TextInput
                                                    className={
                                                        styles.formDetailswidth
                                                    }
                                                    label="Linkedin Profile"
                                                    name="linkedinProfile"
                                                    type="text"
                                                    id="linkedinProfile"
                                                    width="20rem"
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            </div>
                                            <div className={styles.phoneDiv}>
                                                <div>
                                                <PhoneInput
                                                    label="Phone*"
                                                    // isRequired
                                                    name="phoneNumber"
                                                    type="text"
                                                    id="phoneNumber"
                                                    placeholder="(xxx) xxx-xxxx"
                                                    width="20rem"
                                                    onChange={handleChange}
                                                    value={phoneNumber}
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    />
                                                    <p style={{color:'red' ,fontSize:'10px'}}>{phoneError}</p>
                                                    </div>
                                                <div
                                                    className={
                                                        styles.formDetailscheckbox
                                                    }
                                                >
                                                    <p>
                                                        Are you an active job
                                                        seeker?
                                                    </p>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="Yes, I am actively searching for a
                                                        job"
                                                        >
                                                        Yes, I am actively
                                                        searching for a job
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, but I am open to the right
                                                        opportunity"
                                                        >
                                                        No, but I am open to the
                                                        right opportunity
                                                    </FormRadioInput>
                                                    <FormRadioInput
                                                        name="activeJobSeeker"
                                                        id="activeJobSeeker"
                                                        value="No, I am just browsing"
                                                        >
                                                        No, I am just browsing
                                                    </FormRadioInput>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    { 
                                     selectedRole === 'talent' && 
                                        <>
                                        <div style={{display:"flex", flexDirection:'column', width:"24%"}}>
                                        <label style={{fontSize:'11px', textAlign:'center'}}>Video or Audio Intro</label>
                                      
                                      <label className={styles.uploadFileLabel}  for="actual-btn" >Upload file</label>
                                        <input
                                            id="actual-btn"
                                            hidden
                                            //  ref={inputRef}
                                            className="VideoInput_input"
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".mov,.mp4, .mp3"
                                            />
                                            </div>
                                            </>
                                            }
                                            {source && (
                                                <><span className={styles.nofilechosen}>{ source && source?.name || 'No file chosen'}</span>
                                            
                                                <span
                                                className={styles.closeFileIcon}
                                                onClick={() => {
                                                    setSource(null)
                                                    setFileError('')
                                                }}
                                                >
                                                    x
                                                </span>
                                                <p style={{color:'red', fontSize:'13px'}}>{fileError}</p>
                                                </>
                                            )}
                                    <Row jContent="flex-end" mt="18px">
                                        <Button
                                            type="submit"
                                            disabled={loading ||fileError }
                                            >
                                            {loading ? (
                                                <Spinner
                                                    animation="border"
                                                    variant="light"
                                                />
                                            ) : selectedRole === 'employer' &&
                                              !user.organization_id ? (
                                                'Next'
                                            ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Row>
                                    {result && (
                                        <Row jContent="center" mt="18px">
                                            <Message color={result.color}>
                                                {result.message}
                                            </Message>
                                        </Row>
                                    )}
                                </StyledForm>
                            )
                        }}
                    </Formik>
                </MainPanel>
                
            </div> */}
        </>
    )
}

export default ContactDetailInterviewPage
