import React, { useState, useEffect } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Image from 'react-bootstrap/Image'
import ProfileAvatarEmployer from '../../common/ProfileAvatar/ProfileAvatarEmployer'
import RecruiterOrganization from '../../pages/RecruiterOrganization/RecruiterOrganization'
import styles from './styles/Navbar.module.scss'
import styleNew from './styles/NavbarEmployer.scss'
import LogoWithoutText from '../../../../assets/images/Cardinal.png'
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import Signup from './Signup/Signup'
import { getOrganizationFavorite } from '../../api/recruiter.api'
import feather from 'feather-icons'
import Util from '../../../utils/util'
import { Button, Modal, Row } from 'react-bootstrap'

const NavbarLoginAI = ({
    base_url,
    host_url,
    is_groups_url
}) => {
    const currentPathname = window.location.pathname
    const search = location.search
    const pageName = new URLSearchParams(search).get('page')
    return (
        <>
            <Navbar expand="lg" sticky="top" style={{zIndex: 1040, borderBottom: '6px solid #005999' }}>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={LogoWithoutText}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />{' '}
                    <span>CardinalTalent</span>{' '}
                    <span style={{ fontSize: '0.9rem' }}>AI Interviewer</span>
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link href="#employee">Employers</Nav.Link>
                        <Nav.Link href="#talent">Talent</Nav.Link>
                        <Nav.Link href="#recruiters">Recruiters</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    )
}

export default NavbarLoginAI
