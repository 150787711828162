import React, { useEffect, useState } from 'react'
import { Formik, Form as FormF, useField } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { Alert, Spinner, Row, Col, Form } from 'react-bootstrap'
import axios from 'axios'
import bannerImage from '../../../../assets/v2/imgs/banner/banner.png';
// import unionImage from '../../../../assets/v2/imgs/banner/union.svg';
// import docsImage from '../../../../assets/v2/imgs/banner/docs.svg';
// import tickImage from '../../../../assets/v2/imgs/banner/tick.svg';
import googleImage from '../../../../assets/v2/imgs/google1.png';
import { makeRequest } from '../../common/RequestAssist/RequestAssist'
import { GoogleButton } from './styles/Signup.styled'
import styles from './styles/Signup.module.scss'
// import UserForm from './UserFormV2'
import './styles/default.css';

const initialFormData = {
  email: '',
  password: '',
}

const singInValidation = {
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required('Password is required'),
}

const SigninV2Page = (props) => {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState(initialFormData)
  const [response, setResponse] = useState({})
  // const [isSignUp, setIsSignUp] = useState(false)

  useEffect(() => {
    // if (props.isError) {
    //   setIsSignUp(true)
    // }
  }, [props])

  const validationSchema = Yup.object({ ...singInValidation })

  const resendConfirmation = () => {
    setResponse({})
    const resendformData = new FormData()
    resendformData.append(`user[email]`, formData.email)
    const url = `/resend_confirmation`
    makeRequest(url, 'post', resendformData, {
      contentType: 'application/json',
      loadingMessage: 'Submitting...',
      createSuccessMessage: () => 'Recruiter member added successfully',
      createErrorMessage: (e) => e.response.data.msg,
      onSuccess: () => {
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      },
    })
  }

  const handleGoogleSignIn = async () => {
      window.location.href = `/user/auth/google_oauth2`
  }

  const handleSignIn = async (values, resetForm) => {
    setResponse({})
    setFormData({ ...formData, ...values })
    const payload = new FormData()
    const url = '/users/sign_in'
    payload.append('user[email]', values.email)
    payload.append('user[password]', values.password)
    payload.append('format', 'js')
    setLoading(true)
    await axios
      .post(url, payload)
      .then((response) => {
        setTimeout(() => {
          if (response.data.role == 'talent'){
            window.location = '/communities'
          }else{
            window.location = '/'
          }
        }, 2000)
        localStorage.setItem('user', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
        setResponse(error?.response?.data)
        resetForm();
        setLoading(false)
      })
  }

  const TextField = ({ label, ...props }) => {
    const [field] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>
          {label}
        </label>
        <input {...field} {...props} />
      </>
    );
  };
  const currentHost = window.location.host;

  return (
    <section className="container mt-100 d-flex justify-content-center align-items-center">
        <Row className="d-flex w-75 shadow-lg">
          <Col className="col-md-6 justify-content-center d-flex flex-column text-left" style={{ background: "radial-gradient(124.94% 92.25% at 8.13% 89.83%, #D6C5F8 0%, #B0C0F2 33.09%, #7DB6F8 97%)",borderRadius: "20px 0px 0px 20px" }}>
            <img src={bannerImage} className="img-responsive" />
          </Col>
          <Col className="col-md-6 px-5 py-4">
            <h5 class="font-weight-bold text-left">Welcome Back</h5>
            <h6 class="font-weight-normal text-left mt-2">Enter your credentials</h6>
            <div className="mb-3 mt-4">
              
            {!_.isEmpty(response) && (
                <Alert
                  variant={response.messageType}
                  onClose={() => setResponse({})}
                  style={{ fontSize: '15px', paddingRight: '1rem' }}
                  dismissible
                  className="candidate-info-close"
                >
                  <span className={styles.alertInfo}> {response.message} </span>
                </Alert>
              )}
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialFormData}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => { handleSignIn(values, resetForm) }}
              >
                {({ handleChange, handleBlur, handleSubmit, touched, errors}) => (
                <FormF onSubmit={handleSubmit}>
                  {_.isEmpty(response) && (
                    <div className={styles.onResponse}>
                      {response.messageType === 'warning' && (
                        <a href="#" onClick={() => resendConfirmation()}>
                          Click here to resend confirmation
                        </a>
                      )}
                    </div>
                  )}
                  <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <svg width="24" height="24" class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="rgba(0,123,255,1)" viewBox="0 0 20 16">
                                <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z" />
                                <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z" />
                            </svg>
                        </span>
                    </div>
                    <Form.Control type="email" class="form-control" name="email" onChange={handleChange} onBlur={handleBlur} placeholder="Email Address"/>
                  </div>
                  {touched.email && errors.email ? (
                      <span className="text-danger">{errors.email}</span>
                    ) : null}
                  <div class="input-group mt-3">
                      <div class="input-group-prepend">
                          <span class="input-group-text">
                              <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                  fill="rgba(0,123,255,1)">
                                  <path
                                      d="M19 10H20C20.5523 10 21 10.4477 21 11V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11C3 10.4477 3.44772 10 4 10H5V9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9V10ZM5 12V20H19V12H5ZM11 14H13V18H11V14ZM17 10V9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9V10H17Z">
                                  </path>
                              </svg>
                          </span>
                      </div>
                      <Form.Control type="password" name="password" onChange={handleChange} onBlur={handleBlur} className="form-control" placeholder="Password" />
                  </div>
                  {touched.password && errors.password ? (
                      <span className="text-danger">{errors.password}</span>
                    ) : null}
                  <div className="second-action-container" style={{ marginTop: '20px' }}>
                    <a className="button secondaryAction" href="/users/password/new" style={{ textDecoration: 'none' }}>
                      <p className="auth-secondary-text" style={{ margin: 0, float: 'right' }}>Forgot your password?</p>
                    </a>
                  </div>  
                  <div className="text-center mt-5">
                    <button type="submit" disabled={loading} class="btn-block btn btn-primary btn-width">{loading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Sign In'
                    )}
            </button>
                  </div>
                </FormF>
                )}
              </Formik>

              <div className="d-flex align-items-center text-muted text-center my-2">
                <span className="flex-grow-1 border-top"></span>
                <span className="px-2">or</span>
                <span className="flex-grow-1 border-top"></span>
              </div>

              <GoogleButton
                  onClick={() => handleGoogleSignIn()}
                >
                <img src={googleImage} />
                Sign in with Google
              </GoogleButton>

            </div>

            <p className="text-center mt-4 font-size-4">Don't have an account? <a href="/signup/employer">Sign Up</a></p>
        </Col>
        </Row>
    </section>
  )
  // return (
  //   <div>
  //     {/* <main className="d-flex align-items-center"> */}
  //     <section className="container mt-80">
  //       <div className="banner-hero hero-1">
  //         <div className="banner-inner">
  //           <div className="row align-items-center">
  //             <div className="col-lg-6 col-md-6 communities-create">
  //               {/* {(currentHost == 'cardinalgroups.com' || currentHost == 'www.cardinalgroups.com' ? */}
  //                 <>
  //                   <div className="build_community pl-40">
  //                     <h1>Everything you need to build community and make money online.</h1>
  //                     <div className="build_community_info">
  //                       <div> 📈 Highly engaged</div>
  //                       <div> ❤️ Simple to setup</div>
  //                       <div> 🙂 It's fun</div>
  //                       <div> 💰 Charge for subscription membership</div>
  //                       <div> 🌎 Global distribution</div>
  //                     </div>
  //                     <div>
  //                       <a target="_blank" rel="noopener noreferrer" href="mailto:help@cardinalgroups.ai">help@cardinalgroups.com</a>
  //                     </div>
  //                   </div>
  //                 </>
                  
  //                 {/* <div className="banner-imgs" style={{ width: '70%', margin: '0px auto' }}>
  //                   <img alt="CardinalTalent" src={bannerImage} className="img-responsive shape-1" />
  //                   <span className="union-icon"><img alt="CardinalTalent" src={unionImage} className="img-responsive shape-3" /></span>
  //                   <span className="docs-icon"><img alt="CardinalTalent" src={docsImage} className="img-responsive shape-2" /></span>
  //                   <span className="tick-icon"><img alt="CardinalTalent" src={tickImage} className="img-responsive shape-3" /></span>
  //                 </div>
  //               )} */}
  //             </div>
  //             <div className="col-lg-6 bg-white" style={{ maxWidth: '500px', margin: '0px auto' }}>
  //               <div className="block-banner login">
  //                 <div className="right-content" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
  //                   <h3 className="form-title text-center">Log in</h3>

  //                   {!_.isEmpty(response) && (
  //                     <Alert
  //                       variant={response.messageType}
  //                       onClose={() => setResponse({})}
  //                       style={{ fontSize: '15px', paddingRight: '1rem' }}
  //                       dismissible
  //                       className="candidate-info-close"
  //                     >
  //                       <span className={styles.alertInfo}> {response.message} </span>
  //                     </Alert>
  //                   )}
  //                   <Formik
  //                     validateOnChange={false}
  //                     validateOnBlur={false}
  //                     initialValues={initialFormData}
  //                     validationSchema={validationSchema}
  //                     onSubmit={(values, { resetForm }) => { handleSignIn(values, resetForm) }}
  //                   >
  //                     <Form>
  //                       <UserForm />
  //                       <div className="second-action-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px' }}>
  //                         <div className="remember-me-container" style={{ display: 'flex' }}>
  //                           <input type="checkbox" name="remember" className="sonara-checkbox checkbox1" id="id_remember" />
  //                           <label htmlFor="id_remember">
  //                             <p className="auth-secondary-text" style={{ margin: 0, color: '#666' }}>Remember me</p>
  //                           </label>
  //                         </div>
  //                         <a className="button secondaryAction" href="/users/password/new" style={{ color: '#007bff', textDecoration: 'none' }}>
  //                           <p className="auth-secondary-text" style={{ margin: 0, color: '#666', textDecoration: 'underline' }}>Forgot your password?</p>
  //                         </a>
  //                       </div>
  //                       {_.isEmpty(response) && (
  //                         <div className={styles.onResponse}>
  //                           {response.messageType === 'warning' && (
  //                             <a href="#" onClick={() => resendConfirmation()}>
  //                               Click here to resend confirmation
  //                             </a>
  //                           )}
  //                         </div>
  //                       )}
  //                       <div className="text-center mt-4">
  //                         <button type="submit" disabled={loading} className="btn btn-default font-heading icon-send-letter btn1 signupbtn w-50">{loading ? (
  //                           <Spinner animation="border" variant="light" />
  //                         ) : (
  //                           'Sign In'
  //                         )}
  //                         </button>
  //                       </div>
  //                       {/* <span className="signup-link text-center">Don't have an account? <a href="signup.html" style={{textDecoration: 'underline'}}>Sign up</a></span>  */}
  //                     </Form>
  //                   </Formik>
  //                   {/* <span className="signup-link text-center">Don't have an account? <a href="signup.html" style={{textDecoration: 'underline'}}>Sign up</a></span> */}
  //                   <div className="hr-separator">
  //                     <span className="separator">OR</span>
  //                   </div>
  //                   <div className="text-center">
  //                     <a
  //                       className="button is-google hero_buttons w-button"
  //                       style={{ cursor: 'pointer' }}
  //                       onClick={() => handleGoogleSignIn()}
  //                     >
  //                       <img src={googleImage} alt="Google Logo" width={16} height={16} style={{ margin: '10px' }} />
  //                       Sign In with Google
  //                     </a>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //     {/* </main> */}
  //   </div>
  // )
}

export default SigninV2Page
