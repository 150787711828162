import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Row, Col} from "react-bootstrap"
import '../styles/AiInterview.scss'
import InviteInterviewPage from './InviteInterviewPage'


const InterviewDetail = ({organization, interview, totalInvite, job, aiQuestions}) => {
    const [jobQuestions, setJobQuestions] = useState([])
    const [inviteInterviewModal, setInviteInterviewModal] = useState(false)

    // Fetch existing interview question 
    const getInterviewQuestion = async (job_id) =>{
        setJobQuestions([])
        const url =`/ai_interview/${job_id}`
        const responce = await axios.get(url).then(res=>res).catch(error=>console.log(error))
        const responceJob = responce?.data
        if(responceJob?.success){
            setJobQuestions(responceJob.list)
        }
    }

    useEffect (() => {
        getInterviewQuestion(job.id)
    },[])

    return (
        <>
            <div className="container-fluid ai-containter mb-10">
                <div className="d-flex justify-content-between align-items-center pb-3 mt-5">
                    <div className="d-inline-flex align-items-center">
                        { organization && organization?.image_url && organization?.image_url != null &&
                            <img
                                alt={ organization.name} src={ organization.image_url}
                                width="50" height="50"
                                className="d-inline-block align-top"
                            />
                        }
                        {' '}
                        <div className="ml-2">
                            <p className="mb-n1">{ organization.name}</p>
                            <a href={ organization.website_url} target='_blank' className="text-decoration-underline text-primary">{ organization.website_url }</a>
                        </div>
                    </div>
                </div>
                <div className="border rounded-lg p-4 shadow">
                    
                    <div className="d-flex justify-content-between align-items-center">
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb breadcrumb-ai">
                                <li className="breadcrumb-item"><a href="/ai_interviews/create">AI Interviews</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{ interview.interview_title }</li>
                            </ol>
                        </nav>
                        {/* <p className="mb-n1" style={{fontWeight: 550}}>{ job.name }</p> */}
                        <div>
                            <a  href="#" onClick={(e)=> setInviteInterviewModal(true)}>Invite Candidate</a>
                            <a href={`/ai_interviews/${interview.id}/invites`} className='ml-4'>Candidates Invited({totalInvite})</a>
                        </div>
                    </div>
                </div>
                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right flex-column pt-5 pl-4">
                        <label className='mb-0'><b>Interview Title</b></label>
                        <div><p>{ interview.interview_title }</p></div>
                    </div>
                </div>

                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right flex-column pt-1 pl-4">
                        <label className='mb-0'><b>Job Role</b></label>
                        <div><p>{ interview.interview_type }</p></div>
                    </div>
                </div>

                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right flex-column pt-1 pl-4">
                        <label className='mb-0'><b>Job</b></label>
                        <div><p>{ job.name }</p></div>
                    </div>
                </div>

                <div className="justify-content-between align-items-right">
                    <div className="d-flex align-items-right flex-column pt-1 pl-4">
                        <label className='mb-0'><b>Skill</b></label>
                        <div className='mt-3'>
                            { ((job.skills).split(',')).map((value) =>{
                                return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                            })}
                            { interview.addition_skill != '' && interview.addition_skill != null &&
                             <>
                                { ((interview.addition_skill).split(',')).map((value) =>{
                                    return <span key={value} className='skill-bubble-custom mr-1'>{value}</span>
                                })}
                             </>
                            }
                        </div>
                    </div>
                </div>

                <div className="justify-content-between align-items-right mt-4">
                    <div className="d-flex align-items-right flex-column pt-1 pl-4">
                        <label className='mb-0'><b>Interview Questions</b></label>
                    </div>
                    <div className="d-flex align-items-right flex-column pt-1 pl-5 mt-2">
                        <>
                        <label className='mb-2' style={{fontWeight: 510}}>Custom Questions</label>
                            { jobQuestions.map((value,index) =>{ 
                                return <span className='pt-2'>{index + 1}.{'  '}  {value.question}</span>
                            })}
                        </>
                    </div>
                    <div className="d-flex align-items-right flex-column pt-1 pl-5 mt-2">
                        <>
                        <label className='mb-2' style={{fontWeight: 510}}>AI Generated</label>
                            { aiQuestions.map((value,index) =>{ 
                                return <span className='pt-2' key={index}>{value.question}</span>
                            })}
                        </>
                    </div>

                </div>
            </div>

            {inviteInterviewModal ? 
                <InviteInterviewPage
                inviteInterviewModal={inviteInterviewModal}
                setInviteInterviewModal={setInviteInterviewModal}
                interview_id={interview.id}
                />
                : ''
            }
        </>
      );

}
export default InterviewDetail