import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Spinner ,ProgressBar} from "react-bootstrap";
import { makeRequest } from "../../../common/RequestAssist/RequestAssist";
import Webcam from 'react-webcam';
import axios from 'axios';
import maryImage from '../../../../../assets/images/mary.png';
import interviewImage from '../../../../../assets/images/ai_interview/aiinterview.png';
import interviewGif from '../../../../../assets/images/ai_interview/aiinterview.gif';

const InterviewScreeningPage = ({setScreenInterviewModal, screenInterviewModal,interviewDetail, organization}) => {
    const [interviewStarted, setInterviewStarted] = useState(false); // For starting interview
    const [questions, setJobQuestions] = useState([]);
    const [actualQuestions, setAJobQuestions] = useState([]);
    const [recording, setRecording] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const [recordedVideos, setRecordedVideos] = useState([]); // Store individual question-answer videos
    const [progress, setProgress] = useState(0); // Progress percentage
    const [time, setTime] = useState(15 * 60); // Initialize with 15 minutes (900 seconds)
    const [doneEnabled, setDoneEnabled] = useState(false); // Enable/Disable "Done Answering"
    const [isSpeaking, setIsSpeaking] = useState(false); // Track when AI is speaking
    const [transcripts, setTranscripts] = useState([]); // Store transcripts for each question
    const [currentTranscript, setCurrentTranscript] = useState('');  // Store real-time transcript
    const [loading, setLoading] = useState(true);
    const [recognition, setRecognition] = useState(null); // Speech recognition instance
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null); // MediaRecorder for individual recordings
    const fullInterviewRecorderRef = useRef(null); // MediaRecorder for full interview
    const videoChunks = useRef([]); // Store chunks for individual question videos
    const fullInterviewChunks = useRef([]); // Store chunks for full interview
    const [fullInterviewURL, setFullInterviewURL] = useState(null); // Store full interview video URL
    console.log(interviewDetail)
    // Function to speak the current question using the Web Speech API
    const speakQuestion = (text) => {
        if ('speechSynthesis' in window) {
            const speech = new SpeechSynthesisUtterance(text);
            speech.lang = 'en-US';  // You can change this based on language needs
            speech.pitch = 1;       // Adjust pitch if needed (0 to 2, default 1)
            speech.rate = 1;        // Adjust rate of speech if needed (0.1 to 10, default 1)

            // Start speaking
            setIsSpeaking(true);

            window.speechSynthesis.speak(speech);

            // Disable "Done Answering" while the AI is speaking
            setDoneEnabled(false);

            // Once the AI finishes speaking, automatically start recording and enable "Done Answering"
            speech.onend = () => {
                if (isComplete) {
                    window.location.reload();
                }
                startRecording();
                setIsSpeaking(false); // Stop showing waves
                setDoneEnabled(true);
            };
        } else {
            console.warn("Speech Synthesis is not supported in this browser.");
        }
    };
    

    // Set up Speech Recognition for real-time transcription
    useEffect(() => {
        if ('webkitSpeechRecognition' in window || 'SpeechRecognition' in window) {
            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            const recognitionInstance = new SpeechRecognition();
            recognitionInstance.continuous = true;
            recognitionInstance.interimResults = true;
            recognitionInstance.lang = 'en-US'; // Set the language for recognition
            recognitionInstance.onresult = (event) => {
                let interimTranscript = '';
                let finalTranscript = '';

                for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcriptText = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    finalTranscript += transcriptText + ' ';
                } else {
                    interimTranscript += transcriptText;
                }
                }

                setCurrentTranscript(finalTranscript || interimTranscript); // Update the transcript in real-time
            };
            setRecognition(recognitionInstance);
        } else {
            console.warn("Speech Recognition API is not supported in this browser.");
        }
        async function fetchData() {
            await getInterviewQuestion()
        }
        fetchData();
        const timer = setTimeout(() => {
            setLoading(false);
            setInterviewStarted(true)
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    
    useEffect(() => {
        if (interviewStarted) {
            // Speak the current question whenever the question changes
            speakQuestion(questions[currentQuestion]?.label);
            
        }
    }, [currentQuestion, interviewStarted]);

    useEffect(() => {
        if (isComplete) {
            speakQuestion('Thank you for taking the time to interview with us. We appreciate the insights you shared about your experience and qualifications. Wishing you a wonderful day ahead!');
        }
    }, [isComplete]);


    // Start countdown timer from 15 minutes (900 seconds)
    useEffect(() => {
        let timerInterval = null;
        if (interviewStarted && time > 0) {
            timerInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
        }

        // Clear the interval when the timer reaches 0
        if (time === 0) {
            clearInterval(timerInterval);
        }

        return () => clearInterval(timerInterval); // Cleanup on component unmount
    }, [interviewStarted, time]);

    // Convert time in seconds to MM:SS format
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    // Fetch existing interview question 
    const getInterviewQuestion = async () =>{
        setJobQuestions([])
        const url =`/ai_interviews/fetch_questions/${interviewDetail.interview_id}`
        const responce = await axios.get(url).then(res=>res).catch(error=>console.log(error))
        const responceJob = responce?.data
        if(responceJob?.success){
            if(responceJob.questions.length > 0){
                setAJobQuestions(responceJob.questions)
                responceJob.questions[0].label = 'Hello! I am Mary AI Interviewer. Welcome, excited to get to know you. '+responceJob.questions[0].label
                setJobQuestions(responceJob.questions)
            }
        }
    }

    // Get first letter of first name and full last name
    const getInitialAndLastName = (name) => {
        const nameParts = name.split(' ');
    
        if (nameParts.length < 2) {
          return name; // If there's no last name
        }
    
        const firstInitial = nameParts[0][0]; // Get first letter of first name
        const lastName = nameParts[nameParts.length - 1]; // Get last name
    
        return `${firstInitial}. ${lastName}`;
    };

    // Start recording the candidate's response (for both individual and full interview)
    const startRecording = () => {
        // Start speech recognition
        if (recognition) {
            recognition.start();
        }
        // Start recording individual video
        setRecording(true);
        videoChunks.current = [];
        // Access the stream from the webcam
        const stream = webcamRef.current?.video?.srcObject;
        mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', handleStopRecording);
        mediaRecorderRef.current.start();

        // Start recording the full interview if it's the first question
        if (currentQuestion === 0 && !fullInterviewRecorderRef.current) {
            fullInterviewChunks.current = [];
            fullInterviewRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' });
            fullInterviewRecorderRef.current.addEventListener('dataavailable', (e) => {
                if (e.data.size > 0) {
                fullInterviewChunks.current.push(e.data);
                }
            });
            fullInterviewRecorderRef.current.start();
        }
    };

    // Handle when data becomes available during recording (for individual question)
    const handleDataAvailable = (e) => {
        if (e.data.size > 0) {
            videoChunks.current.push(e.data);
        }
    };

    // Handle stop recording, save the video for the current question
    const handleStopRecording = () => {
        const videoBlob = new Blob(videoChunks.current, { type: 'video/webm' });
        // Save video api call
        handleRecordedVideo(videoBlob)
        const videoURL = URL.createObjectURL(videoBlob);
        setRecordedVideos((prev) => [...prev, videoURL]);
        setCurrentTranscript('')
        // Save the current transcript
        setTranscripts((prev) => [...prev, currentTranscript]);

        // Move to the next question or mark interview complete
        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion((prev) => prev + 1);
            setProgress(((currentQuestion + 1) / questions.length) * 100);
        } else {
            setProgress(((currentQuestion + 1) / questions.length) * 100);
            stopFullInterviewRecording(); // Stop full interview recording after the last question
            setIsComplete(true);
        }
        // Stop speech recognition
        if (recognition) {
            recognition.stop();
        }
        setRecording(false);
    };

    const handleRecordedVideo = async (videoBlob) => {
        const payload = new FormData();
        payload.append("file", videoBlob, `response_${Date.now()}.webm`);
        payload.append("interview_id", interviewDetail.interview_id);
        payload.append("ai_interview_invite_id", interviewDetail.id);
        payload.append('question', actualQuestions[currentQuestion]?.label);
        // payload.append('que_type', actualQuestions[currentQuestion]?.type);
        let is_completed = false
        if (currentQuestion == questions.length - 1) {
            is_completed = true
        }
        payload.append('is_completed', is_completed);
        // Upload video using axios
        let URL = '/ai_interviews/upload_video'
        
        await makeRequest(URL,'post', payload, {
            contentType: 'application/json',
            loadingMessage: '',
            loading: false,
        }).then((res) => {
            if(res.data.messageType == 'success'){

            }
        }) 
    }

    // Stop recording the full interview
    const stopFullInterviewRecording = () => {
        if (fullInterviewRecorderRef.current) {
        fullInterviewRecorderRef.current.stop();
        fullInterviewRecorderRef.current.addEventListener('stop', () => {
            const fullInterviewBlob = new Blob(fullInterviewChunks.current, { type: 'video/webm' });
            const fullInterviewURL = URL.createObjectURL(fullInterviewBlob);
            setFullInterviewURL(fullInterviewURL);
        });
        }
    };

    // Stop recording when the user clicks "Done Answering" for individual questions
    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        setDoneEnabled(false); // Disable "Done Answering" after submitting the answer
    };

    return(
        <>
            <Modal
                show={screenInterviewModal}
                onHide={() => setScreenInterviewModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                scrollable
                centered
                id="modal-start-interview"
                backdrop="static"
            >
                <Modal.Body>
                    <div className={loading ? 'blurred-content' : ''} style={{ position: 'relative' }}>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="d-inline-flex align-items-center">
                                { organization && organization?.image_url && organization?.image_url != null &&
                                    <img
                                        alt={ organization.name} src={ organization.image_url}
                                        width="50" height="50"
                                        className="d-inline-block align-top"
                                    />
                                }
                                <div className="ml-2">
                                    <p className="mb-n1 black-dark-font">{ organization.name}</p>
                                    <a href={ organization.website_url} target='_blank' className="text-decoration-underline text-primary">{ organization.website_url }</a>
                                </div>
                            </div>
                            <div className='w-50 d-inline-flex justify-content-between align-items-center'>
                                <div class="status text-small">
                                    <span>{recording ? 'Recording...' : 'Paused'}</span>
                                    <span>{formatTime(time)}</span>
                                    <span>{currentQuestion + 1}/{questions.length}</span>
                                </div>
                                <ProgressBar className='w-100 mr-2' now={progress} label={`${progress}${'%'}`} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center mb-5">
                            <div className="profile-section">
                                <h5 className="font-weight-bold">AI Interviewer</h5>
                                <div className="position-relative">
                                    <img
                                        alt="AI Interviewer" src={recording ? interviewImage : (isComplete ? interviewImage : interviewGif)}
                                    />{' '}
                                    <div className="profile-name">Mary</div>
                                </div>
                                {isSpeaking && (
                                    <div className="wave-container">
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                        <div className="wave"></div>
                                    </div>
                                )}
                            </div>

                            <div className="chat-section">
                                <h6 className='font-weight-bold mb-2'>AI Interviewer</h6>
                                <p className='mb-3'>{questions[currentQuestion]?.label}</p>
                                <h6 className='font-weight-bold mb-2 mt-1'>You</h6>
                                <p>{currentTranscript}</p>
                            </div>

                            <div className="profile-section">
                                <h5 className="font-weight-bold">Candidate</h5>
                                <div className="position-relative">
                                    <Webcam
                                        audio={true}
                                        ref={webcamRef}
                                        muted={true}
                                        screenshotFormat="image/jpeg"
                                        className="webcam"
                                        videoConstraints={{
                                        width: 175,
                                        height: 175,
                                        facingMode: "user",
                                        }}
                                    />
                                    {/* <div className="profile-name">{interviewDetail.candidate_name}</div> */}
                                    <div className="profile-name">{getInitialAndLastName(interviewDetail.candidate_name)}</div>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-4">
                        {!isComplete && (
                            <Button variant="primary" className="ai-btn-primary" onClick={stopRecording} disabled={!doneEnabled} >Done answering? Continue</Button>
                        )}
                        </div>
                    </div>
                    {loading && (
                        <div className="loader-overlay">
                            <Spinner animation="border" variant="primary" />
                            <p className="mt-3 ml-2 font-weight-bold">Loading AI Interview...</p>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}
export default InterviewScreeningPage;