import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import Select from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import P from '../../Admin/shared/P'
import { makeRequest } from '../../../common/RequestAssist/RequestAssist'
import '../styles/AiInterview.scss';
import PencilIcon from '../../../../../assets/images/icons/pencil-icon-v2.svg'
import DeleteIcon from '../../../../../assets/images/icons/trash-icon-v2.svg'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import CustomRichTextarea from '../../../common/inputs/CustomRichTextarea/CustomRichTextarea'
import _ from 'lodash';


const InterviewCreatePage = ({interviewModalShow, setInterviewModalShow, jobs, jobSkills, currentUser, isInterviewCreated, setInviteInterviewModal, setInterviewId, setShowSuccessInterviewCreate}) => {
    
    const [currentActiveTab, setCurrentActiveTab] = useState('type')
    const [seletedJob, setSelectedJob] = useState({})
    const [jobId, setJobId] = useState('')
    const [interviewTitle, setInterviewTitle] = useState('')
    const [isNewSkill, setIsNewSkill] = useState(false);
    const [existingJobSkill, setExistingJobSkill] = useState([])
    const [newJobSkill, setNewJobSkill] = useState([{ skill: ""}])
    const [jobQuestions, setJobQuestions] = useState([])
    const [newSkill, setNewSkill] = useState('')
    const [question, setQuestion] = useState('')
    const [isQuestionCustom, setIsQuestionCustom] = useState(true)
    const [questionCustomValue, setQuestionCustomValue] = useState(['Custom Questions'])
    const [typeSkillValue, setTypeSkillValue] = useState(['Existing skill of the job'])
    const [errorObj, setErrorObj] = useState({})
    const [questionObj, setQuestionObj] = useState({})
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [mode, setMode] = useState('add')
    const [jobList,setJobList] = useState(jobs)
    const [jobSkillList,setJobSkillList] = useState(jobSkills)
    const [interviewType, setInterviewType] = useState('Software Engineer Role')
    const [isJobFormShow, setIsJobFormShow] = useState(false);
    const [isJobSelectShow, setIsJobSelectShow] = useState(true)
    const [industryOptions, setIndustryOptions] = useState([]);
    const [removed, setRemoved] = useState([]);
    const [validationError, setValidationError] = useState({
        location: null,
        description: null,
        mustHaveSkills: null,
        linkedin_url: null,
        linkedin_url_2: null,
        linkedin_url_3: null,
        category: null,
        tech_groups:null,
        name:null,
        jobSalary:null,
        industry:null
    });
    const initialJob = {
        name: '',
        location: '',
        description: '',
        mustHaveSkills: [],
        department: '',
        notificationemails: '',
        job_salary: '',
        linkedin_url: '',
        linkedin_url_2: '',
        linkedin_url_3: '',
        creator_id: '',
        group: '',
        group_id: '',
        linkedin_job_url: '',
        flag: 'Updated a Job',
        tech_groups: [],
        create_lead: '',
        status:[],
        industry: []
    };
    const [newJob, setNewJob] = useState(initialJob)
    const [isLoading, setIsLoading] = useState(false)
    const [matchedSkillOptions, setMatchedSkillOption] = useState([]);
    const [skillOptions, setSkillOptions] = useState([]);
    const handleKeyPress = async (value, callback) => {
        const url = `/filter_candidate_on_location`;
        const formData = new FormData();
        formData.append('filter_word', value);
        setIsLoading(true);
            const response = await axios
                .post(url, formData)
                .then((res) => res)
          .catch((error) => console.log(error));
            const locationPreferrenceArray = response.data.filter.map(
                ({ id, state, city, country }) => {
                  if(country === 'usa' ){
                    return { value: id, label: `${city}, ${state} (US)` };
                  }else{
                    return { value: id, label: `${city}, ${state}` };
                  }
                }
        );
        callback(locationPreferrenceArray);
        setIsLoading(false);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setNewJob({ ...newJob, [name]: value });
        setValidationError({ ...validationError, name: null });
    
    };
    const handleLocation = (event) => {
        if((event.label)?.trim() != '' && ((event.label)?.trim()).length > 0){
            setNewJob({ ...newJob, location: event });
            setValidationError({ ...validationError, location: null });
        }
    };
    const handleDescriptionChange = (text) => {
        if (_.isEmpty(text)) {
          // To remove tags from empty string
          setNewJob((data) => ({...data, description:text}));
            } else {
                setNewJob((prev) => ({
                    ...prev,
                    description: text, //To replace empty tag
                }));
            }
    
        setValidationError({ ...validationError, description: null });
    };
    const handleMustHaveSkills = (event) => {
        if (newJob.mustHaveSkills.length > event.length) {
          const removedElements = _.difference(newJob.mustHaveSkills, event);
          setRemoved([...removed, ...removedElements]);
          const newSkillOptions = _.differenceBy(skillOptions, removedElements, 'value' );
          setSkillOptions([...newSkillOptions]);
            }
        setNewJob({ ...newJob, mustHaveSkills: [...event] });
        setValidationError({ ...validationError, mustHaveSkills: null });
    };
    const onCreateSkills = (event) => {
        if(event.trim() != '' && (event.trim()).length > 0){
          const newSkill = { value: event, label: event, autoMatched: false };
          const cloneSkills = newJob.mustHaveSkills;
          cloneSkills.push(newSkill);
          setNewJob({ ...newJob, mustHaveSkills: [...cloneSkills] });
          setValidationError({ ...validationError, mustHaveSkills: null });
        }
    };
    const handleIndustry = (event) => {
        setNewJob({ ...newJob, industry: [...event] });
        setValidationError({ ...validationError, industry: null });
    };
    const getLookupIndustry = async () => {
        let fetchedIndustry = [];
        fetchedIndustry = await axios
                .get(`/lookup/industry`)
                .then((res) => {
                    return res.data.industry;
                })
                .catch((error) => {
                    return [];
                });
    
            const industryOptions = fetchedIndustry.map((ind) => {
              return { value: ind.value, label: ind.key};
            });
            setIndustryOptions([...industryOptions]);
    };
    const checkJobValidation = () => {
        setValidationError({})
        console.log(newJob)
        let isValid = true
        if (_.isEmpty(newJob.location)) {
          console.log('In Side location')
          setValidationError((prev) => ({
            ...prev,
            location: 'Location is required',
          }));
          isValid = false
        }
        if ( (_.isEmpty(newJob.description.replace(/<[^>]+>/g, ''))) || ((newJob.description.trim()) == '' )) {
          setValidationError((prev) => ({
              ...prev,
              description: 'Job description is required',
          }));
          isValid = false
        }
        if (_.isEmpty(newJob.mustHaveSkills)) {
          setValidationError((prev) => ({
              ...prev,
              mustHaveSkills: 'Must have skills are required',
          }));
          isValid = false
        }
       
          if (_.isEmpty(newJob.job_salary) || ((newJob.job_salary.trim()).length == 0)) {
            setValidationError((prev) => ({
                ...prev,
                jobSalary: 'Job salary is required',
            }));
            isValid = false
          }
        
        if (newJob.industry?.length == 0) {
          setValidationError((prev) => ({
              ...prev,
              industry: 'Industry is required',
          }));
          isValid = false
        }
        if(!_.isEmpty(newJob.linkedin_url)){
          const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
          if(!(regex.test(newJob.linkedin_url) && newJob.linkedin_url.includes("linkedin")))
          {
            setValidationError((prev) => ({
              ...prev,
              linkedin_url: 'Invalid Linkedin URL',
            }));
            isValid = false
          }
        }
        if (_.isEmpty(newJob.linkedin_url)) {
          setValidationError((prev) => ({
            ...prev,
            linkedin_url: 'Linkedin URL is required',
          }));
          isValid = false
        }
        const regex = new RegExp(/^[a-zA-Z0-9_]+$/)
        const numberRegex = new RegExp(/^[0-9]+$/)
        const spaceRegex = new RegExp( /^[A-Za-z\s]*$/)
        if ( (_.isEmpty(newJob.name) ) || ((newJob.name.trim()).length == 0) || (!(regex.test(newJob.name))) && (!(numberRegex.test(newJob.name))) && (!(spaceRegex.test(newJob.name)))) {
          setValidationError((prev) => ({
            ...prev,
            name: 'Please enter valid position title',
          }));
          isValid = false
        } 
        return isValid
    }

    const onJobTypeSubmit = async (event) => {
        if(jobId == ''){
            event.preventDefault()
            const isValid = checkJobValidation()
            if (isValid) {
                await handleSubmitJob(newJob)
            }
            event.preventDefault()
            event.stopPropagation()
        }else{
            setCurrentActiveTab('skill')
            setNewJob(initialJob)
            setValidationError({})
            setIsJobFormShow(false)
            setIsJobSelectShow(true)
        }
      }

    const backSetJobType = () => {
        setCurrentActiveTab('job')
        setIsJobFormShow(false)
        setIsJobSelectShow(true)
    }

    const onCreateJobClick = () => {
        setIsJobFormShow(true)
    }

    const handleSubmitJob = async (job) => {
        console.log(job);
        let jobData = new FormData()
        const url = '/jobs.json'
        const skillsString = job.mustHaveSkills.map((skill)=> skill.value).join(',')
        const techGroupString = job?.tech_groups?.map((tech_group)=> tech_group.value).join(',')
        const industryString = job?.industry?.map((ind)=> ind.value).join(',')
        jobData.append('job[name]', job.name)
        jobData.append('job[location]', job.location.label)
        jobData.append('job[description]', job.description)
        jobData.append('job[skills]', skillsString)
        jobData.append('job[department]', '')
        jobData.append('job[linkedin_url]', job.linkedin_url)
        jobData.append('job[industry]', industryString)
        jobData.append('job[job_salary]', job.job_salary)
        const responce = await makeRequest(url, 'post', jobData, {
            contentType: 'application/json',
            loadingMessage: 'Submitting...',
            createResponseMessage: (responce) => {
                console.log(responce)
                if(responce && responce?.id){
                    setJobId(responce?.id)
                    setSelectedJob({ label: responce?.name, value: responce?.id})
                    setJobList(prevJobList => [
                        ...prevJobList, 
                        { label: responce?.name, value: responce?.id}  // Add the new skill here
                    ]);
                    setExistingJobSkill(responce?.skills.split(','))
                    setCurrentActiveTab('skill')
                    setIsJobFormShow(false)
                    setIsJobSelectShow(true)
                    setNewJob(initialJob)
                    setValidationError({})
                }
            },
        })
    }

    const handleSkillSelect = (e) => {
        setIsNewSkill(false);
        const { value, checked } = e.target;
        if(typeSkillValue.includes('Add additional skills')){
            setIsNewSkill(true);
        }
        if(value == 'Add additional skills' && checked == false){
            setIsNewSkill(false);
        }
        if(value == 'Add additional skills' && checked == true){
            setIsNewSkill(true);
        }
        setTypeSkillValue((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((val) => val !== value);
            }
        });
    }

    const removeNewSkill = (index) =>{
        const updatedCandidates = newJobSkill.filter((_, i) => i !== index);
        setNewJobSkill(updatedCandidates);
    }
    // Handler to add a new row for skill
    const addNewSkill = () => {
        if(newJobSkill.length < 5){
            setNewJobSkill([...newJobSkill, { skill: ""}]);
        }
    }

    const onChangeSkill = (index, event) => {
        const { name, value } = event.target;
        const updatedSkill = newJobSkill.map((jobSkill, i) =>
        i === index ? { ...jobSkill, ['skill']: value } : jobSkill
        );
        setNewJobSkill(updatedSkill);
       
    }

    const handleQuestionSelect = (e) => {
        const { value, checked } = e.target;
        if(questionCustomValue.includes('Custom Questions')){
            setIsQuestionCustom(true)
        }
        if(value == 'Custom Questions' && checked == false){
            setIsQuestionCustom(false)
        }
        if(value == 'Custom Questions' && checked == true){
            setIsQuestionCustom(true)
        }
        setQuestionCustomValue((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((val) => val !== value);
            }
        });
    };


    const onCreateInterviewModalHide = () =>{
        setCurrentActiveTab('type')
    }

    // on job change handling skill
    const onJobChange = (e) =>{
        setErrorObj({})
        setJobQuestions([])
        setExistingJobSkill([])
        if(e?.value != undefined && e?.value != ''){
            setJobId(e.value)
            setSelectedJob(e)
            const jobId = e.value;
            const jobSkill = jobSkills[jobId]; // Access the skills by job ID
            if (jobSkill) {
                setExistingJobSkill(jobSkill.split(','))
            }
            getInterviewQuestion(jobId)
        }
    }

    const onChangeInterviewType = (e) => {
        setInterviewType(e.target.value)
    }

    const onChangeTitle = (e) => {
        setInterviewTitle(e.target.value)
    }
    
    // Fetch existing interview question 
    const getInterviewQuestion = async (job_id) =>{
      setJobQuestions([])
      const url =`/ai_interview/${job_id}`
      const responce = await axios.get(url).then(res=>res).catch(error=>console.log(error))
      const responceJob = responce?.data
      if(responceJob?.success){
        setJobQuestions(responceJob.list)
      }
    }

    const handleQuestion = (event) => {
        setQuestion(event.target.value)
     };

     // question management edit/add api call
     const handleQuestionSubmit = (event) => {
        if(question?.trim() !== '' ){ 
            let URL = '/add_ai_interview_question'
            let method = 'post'
			if(mode === 'edit'){
				URL = `/update_ai_interview_question/${questionObj.id}`
                method = 'put'
			}
			const payload = new FormData()
			payload.append('question_param[job_id]', jobId)
			payload.append('question_param[category]', 'Non-technical')
			payload.append('question_param[question]', question)
            if(mode != 'edit'){
                payload.append('question_param[created_by]', currentUser.id)
            }
			//headers
			const CSRF_Token = document
				.querySelector('meta[name="csrf-token"]')
				.getAttribute('content')
			const headers = {
				headers: {
						'content-type': 'application/json',
						'X-CSRF-Token': CSRF_Token,
				},
			}
			makeRequest(URL,method, payload, headers, {
				contentType: 'application/json',
				loadingMessage: 'Submitting...',
			}).then((res) => {
				if(res.data.success){
                    getInterviewQuestion(jobId)
                    setQuestion('')
                    setQuestionObj({})
                    setMode('add')
				}
			})
        }
     }

     const onEditClick = (obj) => {
        setQuestionObj(obj)
        setQuestion(obj.question)
        setMode('edit')
      }

      const onDeleteClick = (obj) => {
        setQuestionObj(obj)
        setShowDeleteConfirmation(true)
      }
      
      // Delete interview question
      const onDeleteAIQuestion = () => {
        if(questionObj != {} && questionObj != null){
            const url = `/ai_interview/${questionObj.id}`
            makeRequest(url, 'delete',  {}, {   
            contentType: 'application/json',
            createSuccessMessage: (response) => response.data.message,
            onSuccess: (res) => {
                    if(res.data.success){
                        getInterviewQuestion(jobId)
                    }
            },
            })
        }
      }

      const onTypeSubmit = () => {
        if(interviewTitle.trim() != ''){
            getLookupIndustry()
            setCurrentActiveTab('job')
            if(jobList.length == 0){
                setIsJobSelectShow(false)
                setIsJobFormShow(true)
            }
        }else{
            setErrorObj({})
            let errorInterviewType = { title: true}
            setErrorObj(errorInterviewType)
        }
      }

      const onSkillSubmit = () => {
        setErrorObj({}); // Clear previous errors
        // Validate jobId
        if (jobId == '') {
            setErrorObj({ job_id: true });
            return;
        }

        // Check if 'Define additional skill' is selected
        if (typeSkillValue.includes('Define additional skill')) {
            const hasAtLeastOneSkill = newJobSkill.some(jobSkill => jobSkill.skill.trim() !== "");

            // Ensure at least one skill has a value
            if (!hasAtLeastOneSkill) {
            setErrorObj({ additional_skill: true });
            return;
            }
        }
        // Proceed to the next tab
        setCurrentActiveTab('question');
      }

      // intereview creation api
      const submitInterviewData = () => {
        let validate = true
        if(questionCustomValue.length == 0){
            validate = false
                setErrorObj({})
                let errorInterviewType = { questionOption: true}
                setErrorObj(errorInterviewType)
                validate = false
        }
        if(questionCustomValue.includes('Custom Questions')){
            if(jobQuestions.length == 0){
                setErrorObj({})
                let errorInterviewType = { question: true}
                setErrorObj(errorInterviewType)
                validate = false
            }
        }

        if(validate){
            let URL = '/ai_interviews/store'
            let method = 'post'  

            // Prepare the skills payload
            const skills = newJobSkill
                                .map(jobSkill => jobSkill.skill.trim()) // Extract and trim skill values
                                .filter(skill => skill !== "");          // Filter out empty skills

            const payload = new FormData()
            payload.append('interview_param[job_id]', jobId)
            payload.append('interview_param[interview_title]', interviewTitle)
            payload.append('interview_param[interview_type]', interviewType)
            payload.append('interview_param[user_id]', currentUser.id)
            payload.append('interview_param[addition_skill]', skills)
            payload.append('interview_param[question_type]', questionCustomValue.join(',') )

            //headers
            const CSRF_Token = document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute('content')
            const headers = {
                headers: {
                        'content-type': 'application/json',
                        'X-CSRF-Token': CSRF_Token,
                },
            }
            makeRequest(URL,method, payload, headers, {
                contentType: 'application/json',
                loadingMessage: 'Submitting...',
            }).then((res) => {
                console.log(res.data)
                if(res.data.success){
                    setCurrentActiveTab('type')
                    setInterviewType('Software Engineer Role')
                    setInterviewTitle('')
                    setSelectedJob({})
                    setJobId('')
                    setNewJobSkill([{ skill: ""}])
                    setJobQuestions([])
                    setQuestionCustomValue(['Custom Questions'])
                    setInterviewId(res.data.interview.id)
                    setShowSuccessInterviewCreate(true)
                    setInterviewModalShow(false)
                }
            })
        }
      }

    return (
        <>
            <Modal
            show={interviewModalShow}
            onHide={() => onCreateInterviewModalHide()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Body>
                <div>
                    <div className="d-flex justify-content-between">
                        <h4 className="font-weight-bold mb-3">Create AI Interview</h4>

                        <span onClick={() => setInterviewModalShow(false)} role="button">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style={{ width: '30px', height: '30px' }}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </span>
                    </div>
                    <Form>
                       { currentActiveTab == 'type' &&
                        <>
                            <Row className="d-flex mt-2">
                                <Col className="w-50 pr-2 text-left">
                                            <Form.Group>
                                                <Form.Label  className='font-weight-bold'>Interview Title</Form.Label>
                                                <Form.Control type="text" placeholder="Enter the interview title" onChange={onChangeTitle} value={interviewTitle}/>
                                                { errorObj && errorObj?.title &&
                                                    <p className='text-error-dannger'>Title is required</p>
                                                }
                                            </Form.Group>
                                </Col>
                            </Row>
                            <Row className='d-flex' style={{paddingTop: '2%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Select the interview type</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col className="w-20 pr-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Software Engineer Role"
                                                id={`default-custom-question-1`}  value="Software Engineer Role"
                                                checked={interviewType === 'Software Engineer Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>

                                <Col className="w-20 pl-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Non-technical Role"
                                                id={`default-custom-question-2`} value="Non-technical Role"
                                                checked={interviewType === 'Non-technical Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col className="w-20 pr-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="STEM Role (Math, Chemistry, Physics, Biology)"
                                                id={`default-custom-question-3`}  value="STEM Role (Math, Chemistry, Physics, Biology)"
                                                checked={interviewType === 'STEM Role (Math, Chemistry, Physics, Biology)'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>

                                <Col className="w-20 pl-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="radio" name="interview_type" label="Recruiter Role"
                                                id={`default-custom-question-4`}  value="Recruiter Role"
                                                checked={interviewType === 'Recruiter Role'} onChange={onChangeInterviewType}
                                            />
                                        </div>
                                </Col>
                            </Row>

                            <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                <Button variant="outline-primary" className="mr-3" disabled>Previous</Button>
                                <Button variant="primary" onClick={onTypeSubmit} className='btn-pk-color'>Next</Button>
                            </div>
                         </>
                        }
                         { currentActiveTab == 'job' &&
                            <div>  
                             { isJobSelectShow && 
                              <>
                                 <Row className="d-flex mt-2">
                                    <Col className="col-6 pr-2 text-left">
                                                <Form.Group>
                                                    <Form.Label className='font-weight-bold'> Select Job</Form.Label>
                                                    <Select
                                                        classNamePrefix="select"
                                                        isSearchable={true}
                                                        name="job_id"
                                                        menuPlacement="auto"
                                                        options={jobList}
                                                        onChange={(e)=> onJobChange(e)}
                                                        value={seletedJob}
                                                        placeholder="Please Select Job"
                                                    />
                                                    { errorObj && errorObj?.job_id &&
                                                        <p className='text-error-dannger'>Job is required</p>
                                                    }
                                                </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex mt-2">
                                    <Col className="w-50">
                                        Or <a href="#" className='ml-2' onClick={onCreateJobClick}> Create Job Post<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(70,146,221,1)" style={{ width: '24px', height: '24px', marginLeft: '5px' }}><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg> </a>
                                    </Col>
                                </Row>
                               </>
                               }
                                { isJobFormShow && 
                                 <>
                                 <Row className="d-flex mt-4">
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Job Title
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange}
                                                value={newJob.name}
                                                name="name"
                                            />
                                            {validationError && validationError?.name && (
                                                 <p className='text-error-dannger'>{validationError.name}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Location
                                            </Form.Label>
                                            <AsyncCreatableSelect
                                                isLoading={isLoading}
                                                loadOptions={handleKeyPress}
                                                placeholder="Search for location"
                                                onChange={handleLocation}
                                                value={newJob.location}
                                            />
                                            {validationError && validationError?.location && (
                                                <p className='text-error-dannger'>{validationError.location}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex mt-2">
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Job Description
                                            </Form.Label>
                                            <CustomRichTextarea
                                                fieldValue={''}
                                                handleContentChange={handleDescriptionChange}
                                            />
                                            {validationError && validationError.description && (
                                                <p className='text-error-dannger'>{validationError.description}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex mt-2">
                                    <Col className="w-50">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Skills
                                            </Form.Label>
                                            <CreatableSelect
                                                isMulti
                                                createOptionPosition="first"
                                                value={newJob.mustHaveSkills}
                                                onChange={handleMustHaveSkills}
                                                options={matchedSkillOptions}
                                                menuPlacement="auto"
                                                onCreateOption={onCreateSkills}
                                            />
                                            {validationError.mustHaveSkills && (
                                                <p className='text-error-dannger'>{validationError.mustHaveSkills}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col className="w-50">
                                        <Form.Group style={{zIndex: '1000000000000'}}>
                                            <Form.Label className='font-weight-bold'>Industry</Form.Label>
                                            <Select
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                name="industry"
                                                menuPlacement="auto"
                                                minMenuHeight={6}
                                                isMulti
                                                onChange={handleIndustry}
                                                options={industryOptions}
                                                value={newJob.industry}
                                                style={{zIndex: '100000'}}
                                            />
                                            {validationError.industry && (
                                                    <p className='text-error-dannger'>{validationError.industry}</p>
                                                )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="d-flex mt-2">
                                    <Col className="col-6">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Salary
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange}
                                                value={newJob.job_salary}
                                                name="job_salary"
                                            />
                                            {validationError.jobSalary && (
                                                <p className='text-error-dannger'>{validationError.jobSalary}</p>
                                            )}
                                        </Form.Group>      
                                    </Col>
                                    <Col className="col-6">
                                        <Form.Group>
                                            <Form.Label className='font-weight-bold'>
                                                Linkedin URL
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                onChange={handleChange}
                                                value={newJob.linkedin_url}
                                                name="linkedin_url"
                                            />
                                            {validationError.linkedin_url && (
                                                <p className='text-error-dannger'>{validationError.linkedin_url}</p>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                </>
                                }
                                <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                    <Button variant="outline-primary" className="mr-3" onClick={() => setCurrentActiveTab('type') }>Previous</Button>
                                    <Button variant="primary" onClick={ (e) => onJobTypeSubmit(e)} className='btn-pk-color'>Next</Button>
                                </div>
                            </div>
                        }
                        { currentActiveTab == 'skill' &&
                        <>
                            <Row className='d-flex' style={{paddingTop: '2%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Please define the skills</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col className="w-50 pr-2 text-left">
                                    <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                        <Form.Check type="checkbox" name="skill"  label="Existing skill of the job" value="Existing skill of the job"
                                            id={`default-skill-1`} checked={typeSkillValue.includes('Existing skill of the job')} onChange={handleSkillSelect}
                                        />
                                    </div>
                                </Col>

                                <Col className="w-50 pl-2 text-left">
                                    <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                        <Form.Check type="checkbox" name="skill" label="Add additional skills" value="Add additional skills"
                                            id={`default-skill-2`} checked={typeSkillValue.includes('Add additional skills')} onChange={handleSkillSelect}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='d-flex'>
                                <Col className="w-50 pr-2 text-left">
                                    { existingJobSkill.map((value,key) =>{
                                        return <span key={key} className='skill-bubble-custom ml-1'>{value}</span>
                                    })}
                                </Col>
                            </Row>
                            { isNewSkill  &&
                            <div>
                                <p className=' mt-3'>Please feel free add skill to increase the accuracy of the questions.</p>
                                {newJobSkill.map((jobSkill, index) => (
                                    <div className='border rounded-lg p-3 shadow  mt-3 mb-2'  key={index}>
                                        <Row className='d-flex'  key={index}>
                                            <Col className="col-md-11 pr-2 text-left">
                                                <Form.Group>
                                                    <Form.Label  className='font-weight-bold'>Enter Skill #{index+1}</Form.Label>
                                                    <Form.Control type="text" placeholder="Enter new skill" name="skill" onChange={(e)=>onChangeSkill(index, event)} 
                                                    value={jobSkill.skill}
                                                />
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-md-1 pr-2 text-right">
                                                {newJobSkill.length > 1 && index !== 0 && (
                                                    <button className="d-inline-flex text-primary pr-2 mt-4" type="button" onClick={() => removeNewSkill(index)} style={{ marginRight: "10px",marginTop: '40px' }}>
                                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#C2040F"><path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M18.8504 9.14001L18.2004 19.21C18.0904 20.78 18.0004 22 15.2104 22H8.79039C6.00039 22 5.91039 20.78 5.80039 19.21L5.15039 9.14001" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.3301 16.5H13.6601" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5 12.5H14.5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                    </button>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <div className="d-inline-flex text-primary pl-2 mt-2">
                                    <p role="button" className="d-inline-flex text-primary pr-2" onClick={addNewSkill} style={{ marginBottom: "10px" }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(0,129,255,1)" style={{width:'24px', height:'24px'}}><path d="M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z"></path></svg> Add another skill (up to 5)
                                    </p>
                                </div>
                            </div>
                            }
                             { errorObj && errorObj?.additional_skill &&
                                    <p className='text-error-dannger mt-1 ml-2'>At least one addition skill is reuired if you select 'Define addition skill" option</p>
                             }
                            <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                <Button variant="outline-primary" className="mr-3" onClick={() => backSetJobType() }>Previous</Button>
                                <Button variant="primary" onClick={onSkillSubmit} className='btn-pk-color'>Next</Button>
                            </div>
                        </>
                        }

                        { currentActiveTab == 'question' &&
                        <>  
                            <Row className='d-flex' style={{paddingTop: '2%'}}>
                                <Col className="w-100 pr-2 text-left">
                                    <Form.Label className='font-weight-bold'>Interview Questions</Form.Label>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col className="w-20 pr-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="checkbox" name="interview_type" label="Custom Questions"
                                              id={`default-custom-question-custom`} onChange={handleQuestionSelect} value="Custom Questions"
                                              checked={questionCustomValue.includes('Custom Questions')}
                                            />
                                        </div>
                                </Col>

                                <Col className="w-20 pl-2 text-left">
                                        <div className="bg-blue-100 py-2 mb-4 px-3 rounded-lg">
                                            <Form.Check type="checkbox" name="interview_type" label="AI Generate Questions"
                                                id={`default-custom-question-ai`} onChange={handleQuestionSelect}  value="AI Generate Questions"
                                                checked={questionCustomValue.includes('AI Generate Questions')}
                                            />
                                        </div>
                                </Col>
                            </Row>
                            { errorObj && errorObj?.question &&
                                 <Row className="d-flex">
                                         <p className='text-error-dannger  ml-3'>Custom question required</p>
                                 </Row>
                            }
                            { errorObj && errorObj?.questionOption &&
                                 <Row className="d-flex">
                                        <p className='text-error-dannger ml-3'>Question type is required</p>
                                 </Row>
                            }
                                
                            { isQuestionCustom &&
                            <>  
                                { jobQuestions && jobQuestions.length > 0 &&
                                <Row className='d-flex'>
                                    <table className='table-centered-ai-intererview'>
                                        <thead>
                                            <tr>
                                                <th>Sr.</th>
                                                <th>Question</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { jobQuestions.length > 0 && jobQuestions && jobQuestions.map((ques, index) => (
                                                <tr>
                                                    <td>{index + 1 }</td>
                                                    <td className='left-td'>{ques.question}</td>
                                                    <td> <img src={PencilIcon} height={20} width={20} className='mr-2 hand-icon' onClick={()=> onEditClick(ques)}/>
                                                        <img src={DeleteIcon}  height={19} width={19} className='hand-icon'  onClick={()=> onDeleteClick(ques)}/>
                                                    </td>
                                                
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Row>
                                }
                                { (jobQuestions.length <= 10 || mode == 'edit' )  && 
                                 <div className='border rounded-lg p-3 shadow  mt-3 mb-2'>
                                    <Row className='d-flex'>
                                        <Col className="col-10 pr-2 text-left">
                                            <Form.Group>
                                                <Form.Label  className='font-weight-bold'>{ mode == 'add' ? 'Enter' : 'Update' } Question</Form.Label>
                                                <Form.Control type="text" placeholder="Enter question" name="question" onChange={(e)=>handleQuestion(e)}  value={question}/>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-2 pr-2 text-left">
                                                <Button variant="outline-primary" onClick={handleQuestionSubmit} style={{marginTop: '31%'}}>{ mode == 'add' ? 'Add' : 'Update' }</Button>
                                        </Col>
                                    </Row>
                                </div>
                                }
                            </>
                            }
                            <div className="d-flex justify-content-end" style={{paddingTop: '2%'}}>
                                <Button variant="outline-primary" className="mr-3" onClick={() => setCurrentActiveTab('skill') }>Previous</Button>
                                <Button variant="primary" onClick={submitInterviewData} className='btn-pk-color'>Submit</Button>
                            </div>
                        </>
                        }
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
            {showDeleteConfirmation &&
                <SweetAlert
                    title="Are you sure you want to delete?"
                    warning
                    showCancel
                    confirmBtnText="Yes, Make it!"
                    confirmBtnBsStyle="danger"
                    onConfirm={()=>(onDeleteAIQuestion(),setShowDeleteConfirmation(false))}
                    onCancel={()=>(setQuestionObj({}),setShowDeleteConfirmation(false))}
                    >
                </SweetAlert>
            }

            
        </>
    )
}
export default InterviewCreatePage;