import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import NavLink from 'react-bootstrap/NavLink'
import styles from './styles/Footer.module.scss'
import LogoWithText from '../../../../assets/images/logos/navbar-logo.svg'

const Footer = ({version,base_url,host_url}) => {
    const today = new Date();
    const currentDate = today.toLocaleDateString();
    const currentYear = today.getFullYear();
    return (
        <Navbar expand="lg"  variant="dark" fixed="bottom" style={{backgroundColor: '#005999'}}>
            <Navbar.Text className="text-white">© {currentYear} CardinalTalent. All rights reserved.</Navbar.Text>

            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav>
                    <Nav.Link className='nav-link-footer' href="/welcome/about_us">About Us</Nav.Link>
                    <Nav.Link className='nav-link-footer' href="/welcome/careers">Career</Nav.Link>
                    <Nav.Link className='nav-link-footer' href="/privacy_policy">Privacy Policy</Nav.Link>
                    <Nav.Link className='nav-link-footer' href="/terms_of_service">Terms & Conditions</Nav.Link>
                    <Nav.Link className='nav-link-footer' href="/ccpa">Do not sell my personal info</Nav.Link>
                    <Nav.Link className='nav-link-footer' href="/optout">OPT out</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Footer
